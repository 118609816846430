<template>
  <div>
    <page-title
      heading="Tabakos API"
      subheading="Tabakos API"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <TabakosApiComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import TabakosApiComponent from "@/components/settings/moduleSettings/TabakosApiComponent";

export default {
  name: "TabakosApi",
  components: { PageTitle, TabakosApiComponent },
  data() {
    return {
      icon: "pe-7s-plane icon-gradient bg-tempting-azure",
    };
  },
};
</script>
