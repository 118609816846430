<template>
  <v-container fluid>
    <!-- add dropzone to upload and excel file -->
    <v-card class="p-0">
      <v-card-title class="card-header headline">
        {{ $t("erp.lang_importItems") }}
      </v-card-title>

      <v-card-text class="py-3">
        <v-row class="p-0">
          <v-col v-if="this.error" cols="12" sm="12" md="12" class="p-4">
            <div class="w-75 mx-auto">
              <v-alert type="error" outlined>
                <div class="d-flex align-center">
                  {{ this.error }}
                </div>
              </v-alert>
            </div>
          </v-col>

          <v-col v-if="this.importing" cols="12" class="text-center pa-10">
            <v-progress-circular
              indeterminate
              color="primary"
              size="50"
              width="4"
            ></v-progress-circular>
          </v-col>

          <v-col v-else cols="12" sm="12" md="12" class="p-4">
            <div class="w-75 mx-auto">
              <file-dropzone
                :file-types="['.csv', '.tka']"
                v-model="file"
                file-icon="mdi-microsoft-excel"
                icon-color="success"
                @startImport="onStartImport"
                :confirm-text="$t('generic.lang_import')"
              ></file-dropzone>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import FileDropzone from "../../common/FileDropzone.vue";

export default {
  name: "TabakosApiComponent",
  components: {
    FileDropzone,
  },
  data() {
    return {
      ENDPOINTS,
      importing: false,
      file: null,
      error: null,
    };
  },

  methods: {
    reset() {
      this.file = null;
      this.error = null;
    },
    onStartImport() {
      this.importing = true;
      const formData = new FormData();
      formData.append("file", this.file);

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.TABAKOS_API.IMPORT, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            Events.$emit("showSnackbar", {
              message: this.$t("erp.lang_itemsSuccessfullImported"),
              color: "success",
            });

            this.reset();
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.importing = false;
        });
    },
  },
};
</script>
